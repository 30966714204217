<div class="wrap-box">
  <div class="wrap-content">
    <h2 class="h2 mb-0">โปรแกรมคำนวณภาษี หัก ณ ที่จ่าย เงินเดือน</h2>
    <ul class="list-item">
      <li class="text-gray-medium" *ngFor="let item of listItems">
        <img src="../../../../assets/images/ico-check-circle-green.svg" width="20" height="20" alt="checked" title="checked">{{ item }}
      </li>
    </ul>
    <button type="button" class="btn btn-primary" (click)="gotoLink()">ดูรายละเอียด</button>
  </div>
  <div class="wrap-image">
    <picture>
      <source type="image/webp" srcset="/assets/images/landing-page/paystation-laptop.webp">
      <source type="image/jpeg" srcset="/assets/images/landing-page/paystation-laptop.png">
      <img srcset="/assets/images/landing-page/paystation-laptop.png" alt="iTAX paystation" title="iTAX paystation">
    </picture>
  </div>
</div>
