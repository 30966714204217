import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../../../environments/environment';

@Component({
  selector: 'app-calculator-paystation-detail',
  templateUrl: './paystation-detail.component.html',
  styleUrls: ['./paystation-detail.component.scss']
})
export class CalculatorPaystationDetailComponent implements OnInit {

  listItems: string[] = [
    "คำนวณภาษี หัก ณ ที่จ่าย",
    "ไฟล์ยื่นภาษี ภ.ง.ด.1, ภ.ง.ด.1ก",
    "ประกันสังคม (สปส.1-10, กท 20ก)",
    "สลิปเงินเดือน / ใบ 50 ทวิ"
  ]

  constructor() { }

  ngOnInit(): void {
  }

  gotoLink(): void {
    window.open(`${ environment.mainBaseUrl }/paystation`, '_blank');
  }

}
